.login-form-container {
    background-color: #f0f2f5;
}

.login-card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-card .ant-card-head {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    border-bottom: none;
}

.login-card .ant-card-body {
    padding: 24px;
}

.login-form-container .ant-form-item {
    margin-bottom: 16px;
}

.login-form-container .ant-btn {
    border-radius: 6px;
}

@media screen and (max-width: 767px) {
    .login-card {
        width: 90%;
    }
}
