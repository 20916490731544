.city-button {
    margin-right: 20px;
    background-color: white;
    color: black;
    border: 1px solid rgba(0,0,0,0.1);
    height: 50px;
    width: 200px;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
}